import React, { useState, useEffect, useRef } from 'react';
import { FaMountain, FaSnowflake, FaHotel, FaHiking, FaUtensils, FaSuitcase, FaCity, FaBed, FaSmile } from 'react-icons/fa';
import Carousel from 'react-bootstrap/Carousel';

import TirupatiImage from './images/tirupati.avif';
import MeghalayaImage from './images/Meghalya.jpg';
import GangotriImage from './images/gangotri.webp';
import image1 from './images/super-deluxe2.jpg';
import image2 from './images/Deluxe-Hill.jpg';
import image3 from './images/SuiteRooms.jpg';
import image4 from './images/WhatsApp Image 2024-11-07 at 23.35.08_a9b33a52.jpg';

const LandingPage = () => {
  const [showContact, setShowContact] = useState(false);

  // Carousel images array
  const localImages = [
    { src: image1, caption: "Super Deluxe", description: "Experience unparalleled luxury in our Super Deluxe rooms." },
    { src: image2, caption: "Deluxe Room", description: "Enjoy comfort and elegance in our Deluxe Rooms." },
    { src: image3, caption: "Suite", description: "Relax in style with our spacious, well-equipped Suites." },
    { src: image4, caption: "Standard Room", description: "Cozy and comfortable, ideal for a peaceful stay." }
  ];

  const tourPackagesData = [
    {
      title: 'Tirupati Darshan - Spiritual Journey',
      image: TirupatiImage,
      location: 'Tirupati, Andhra Pradesh',
      features: [
        { icon: <FaHiking />, text: 'Visit the famous Tirupati temple' },
        { icon: <FaHiking />, text: 'Treks to nearby spiritual hills' },
        { icon: <FaHotel />, text: 'Comfortable stay at temple resorts' }
      ]
    },
    {
      title: 'Meghalaya - A Land of Waterfalls',
      image: MeghalayaImage,
      location: 'Meghalaya, North East India',
      features: [
        { icon: <FaMountain />, text: 'Explore the majestic hills and valleys' },
        { icon: <FaSnowflake />, text: 'Experience the cool weather' },
        { icon: <FaUtensils />, text: 'Enjoy local Khasi cuisine' }
      ]
    },
    {
      title: 'Gangotri - The Gateway to the Himalayas',
      image: GangotriImage,
      location: 'Gangotri, Uttarakhand',
      features: [
        { icon: <FaHiking />, text: 'Sacred Gangotri temple visit' },
        { icon: <FaMountain />, text: 'Trekking in the Himalayas' },
        { icon: <FaSuitcase />, text: 'Adventure tours and sightseeing' }
      ]
    }
  ];

  const summaryData = [
    { icon: <FaCity size={40} />, text: '76+', description: 'Cities', target: 76 },
    { icon: <FaHotel size={40} />, text: '1800+', description: 'Hotels', target: 1800 },
    { icon: <FaBed size={40} />, text: '36K+', description: 'Rooms', target: 36000 },
    { icon: <FaSmile size={40} />, text: '4M+', description: 'Happy Guests', target: 40000 }
  ];
  
  const [counts, setCounts] = useState([0, 0, 0, 0]);
  const summaryRef = useRef(null);

  const animateNumbers = (index, target) => {
    let current = 0;
    const step = target / 200;
    const interval = setInterval(() => {
      current += step;
      if (current >= target) {
        clearInterval(interval);
        current = target;
      }
      setCounts(prevCounts => {
        const newCounts = [...prevCounts];
        newCounts[index] = Math.floor(current);
        return newCounts;
      });
    }, 10);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          summaryData.forEach((item, index) => {
            animateNumbers(index, item.target);
          });
        }
      },
      { threshold: 0.5 }
    );

    if (summaryRef.current) {
      observer.observe(summaryRef.current);
    }

    return () => {
      if (summaryRef.current) {
        observer.unobserve(summaryRef.current);
      }
    };
  }, []);

  return (
    <div className="w-full min-h-screen bg-white text-black font-sans">
    
      {/* Hero Section */}
      <section className="relative w-full h-[450px] md:h-[500px] bg-gradient-to-r from-orange-500 via-orange-600 to-orange-700 text-white flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-orange-400 via-yellow-500 to-orange-600 opacity-80"></div>
        <div className="absolute inset-0 bg-[url('https://via.placeholder.com/1500')] bg-cover bg-center opacity-30"></div>

        <div className="relative z-10 text-center px-6 sm:px-10">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold tracking-wide mb-4 animate__animated animate__fadeInDown animate__delay-1s shadow-lg">
            Discover the World with <span className="text-yellow-400">TabTrips</span>
          </h1>
          <p className="text-sm sm:text-lg lg:text-2xl mb-6 opacity-90 animate__animated animate__fadeInUp animate__delay-1.5s shadow-md">
            Dive into curated travel experiences—from serene temples to breathtaking mountains—all crafted to make your journey unforgettable.
          </p>

          <div className="mb-6 animate__animated animate__zoomIn animate__delay-1.8s">
            <span className="bg-black text-orange-300 px-6 py-3 text-lg sm:text-xl font-semibold shadow-lg">
              🌟 Experience Luxury Travel with TabTrips 🌟
            </span>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-left max-w-3xl mx-auto mt-6">
            <div className="flex items-center space-x-3 animate__animated animate__fadeInLeft animate__delay-2s">
              <span className="text-5xl">🏞️</span>
              <p className="text-lg sm:text-xl font-medium">Adventure Tours & Sightseeing</p>
            </div>
            <div className="flex items-center space-x-3 animate__animated animate__fadeInRight animate__delay-2.2s">
              <span className="text-5xl">🛌</span>
              <p className="text-lg sm:text-xl font-medium">Luxury Stays & Premium Accommodations</p>
            </div>
            <div className="flex items-center space-x-3 animate__animated animate__fadeInLeft animate__delay-2.4s">
              <span className="text-5xl">🍴</span>
              <p className="text-lg sm:text-xl font-medium">Exquisite Dining & Culinary Journeys</p>
            </div>
            <div className="flex items-center space-x-3 animate__animated animate__fadeInRight animate__delay-2.6s">
              <span className="text-5xl">✈️</span>
              <p className="text-lg sm:text-xl font-medium">Tailor-made Packages for Every Traveler</p>
            </div>
          </div>
        </div>
      </section>

      {/* Carousel Section */}
      <div className="px-4 py-8">
        <Carousel className="rounded-lg shadow-lg" controls={false} indicators={false} interval={2000} pause="hover">
          {localImages.map((image, idx) => (
            <Carousel.Item key={idx}>
              <img
                className="d-block w-full h-[400px] object-cover rounded-lg"
                src={image.src}
                alt={image.caption}
                style={{ objectFit: 'cover', width: '100%', height: '400px' }}
              />
              <Carousel.Caption className="bg-black bg-opacity-50 p-4 rounded-lg text-white">
                <h5 className="text-xl font-bold">{image.caption}</h5>
                <p className="text-sm mt-1">{image.description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      {/* Tour Packages Section */}
      <div className="px-4 py-10">
        <h2 className="text-3xl font-bold text-center mb-8">Our Tour Packages</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {tourPackagesData.map((tour, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img src={tour.image} alt={tour.title} className="w-full h-48 object-cover rounded-t-lg" />
              <div className="p-6">
                <h3 className="text-lg font-semibold mb-2">{tour.title}</h3>
                <p className="text-gray-600 mb-4">{tour.location}</p>
                <div className="space-y-3">
                  {tour.features.map((feature, i) => (
                    <div key={i} className="flex items-center text-gray-700">
                      <span className="text-xl mr-2">{feature.icon}</span>
                      <span>{feature.text}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Summary Section */}
      <div ref={summaryRef} className="w-full px-4 py-8 bg-gray-100">
        <div className="max-w-5xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {summaryData.map((item, index) => (
            <div key={index} className="text-center flex flex-col items-center">
              <div className="text-orange-500 mb-2">{item.icon}</div>
              <h4 className="text-2xl font-bold">{counts[index]}+</h4>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Bottom Section */}
      <section className="text-center py-16 mt-8 bg-black text-white rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold mb-6">Ready for an Unforgettable Journey?</h2>
        <p className="text-lg mb-6">
          Let us help you plan the perfect getaway, whether you're looking for relaxation, adventure, or a cultural escape. Reach out today and start your adventure with us!
        </p>

        <button 
          onClick={() => setShowContact(!showContact)} 
          className="bg-orange-500 hover:bg-orange-600 text-white text-lg font-semibold py-2 px-6 rounded-lg"
        >
          Join Us
        </button>

        {showContact && (
          <div className="mt-6 text-center">
            <p className="text-lg">📧 Email: <a href="mailto:parveen@tabtrips.com" className="text-orange-500">parveen@tabtrips.com</a></p>
            <p className="text-sm mt-2">Leave a message, and we will get back to you soon to help plan your perfect journey!</p>
          </div>
        )}
      </section>
    </div>
  );
};

export default LandingPage;
